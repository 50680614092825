import React from "react";
import "./MyData.css";
import { useState, useEffect, useRef } from "react";
import {
  getEventConsumptionDetails,
  postEventConsumptionDetails,
  getTravelModes,
  useApi,
} from "./apiService";
import { useEvent } from "./EventDetailsContext";
import EventName from "./EventName";
import CustomAlert from "./CustomAlert"; // Import CustomAlert component
import { useNavigate } from 'react-router-dom';
import "./assets/styles/font-awesome.css";

const MyData = () => {
  const navigate = useNavigate(); 
  const { eventDetails } = useEvent();
  const [formData, setFormData] = useState({
    Travel: "",
    Transportation: "",
    Location: "",
    Time: "",
    Accomodation: "",
    Meal: "",
  });

  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [notification, setNotification] = useState("");
  const api = useApi();
  console.log("eventdetailsall", eventDetails);
  const [travelModes, setTravelModes] = useState([]);

  useEffect(() => {
    const fetchTravelModes = async () => {
      try {
        const response = await getTravelModes("Individual");
        console.log("Travel Mode", response);
        setTravelModes(response);
      } catch (error) {
        console.error("Error fetching travel modes:", error);
      }
    };

    fetchTravelModes();
  }, []);

  const locationRef = useRef(null);
  const [location, setLocation] = useState("");
  const [TransportOptions, setTransportationOptions] = useState([]);
  const [totalEventDays, setTotalEventDays] = useState(0);

  useEffect(() => {
    if (eventDetails) {
      setFormData({
        Travel: eventDetails.Travel || "",
        Transportation: eventDetails.Transportation || "",
        Location: eventDetails.Location || "",
        Time: eventDetails.Time || "",
        Accomodation: eventDetails.Accomodation || "",
        Meal: eventDetails.Meal || "",
      });

      const startDate = new Date(eventDetails.startDate);
      const endDate = new Date(eventDetails.endDate);
      const timeDifference = endDate.getTime() - startDate.getTime();
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24)) + 1;

      setTotalEventDays(daysDifference);
    }
  }, [eventDetails]);

  useEffect(() => {
    updateTransportationOptions(formData.Travel);
  }, [formData.Travel]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getEventConsumptionDetails(eventDetails.eventId);
        console.log("My Data", res);

        const travelMode = travelModes.find(
          (mode) => mode.travelModeId === res.travelModeId
        );
        console.log("travelmode", travelMode);
        console.log(res);
        setFormData({
          Travel: travelMode ? travelMode.mode : "",
          Transportation: travelMode ? travelMode.type : "",
          TravelmodeId: res.travelModeId,
          Location: res.location,
          locationAddress: res.location,
          Time: res.days,
          Accomodation: res.accommodationId,
          Meal: res.mealPreferenceId,
          LocationID: res.locationId,
        });
        setLocation(res.location);
        updateTransportationOptions(travelMode ? travelMode.mode : "");
      } catch (error) {
        console.log("bye");
      }
    };
    fetchData();
  }, [eventDetails, travelModes]);

  useEffect(() => {
    const initializeAutocomplete = () => {
      const locationInput = locationRef.current;

      const autocomplete = new window.google.maps.places.Autocomplete(
        locationInput,
        {
          componentRestrictions: { country: "in" },
          strictBounds: false,
        }
      );

      autocomplete.setFields([
        "formatted_address",
        "geometry.location",
        "name",
        "place_id",
      ]);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          alert("No details available for input: '" + place.name + "'");
          return;
        }
        console.log(place);
        setLocation(place.formatted_address);
        console.log("Selected place:", place.formatted_address);
        setFormData((prevFormData) => ({
          ...prevFormData,
          locationAddress: place.formatted_address,
        }));
      });
    };

    if (window.google && window.google.maps) {
      initializeAutocomplete();
    } else {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCpdevcXjKt9CVD1n8chB59MGW0d1uT2dg&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = initializeAutocomplete;
      document.head.appendChild(script);
    }
  }, []);

  const updateTransportationOptions = (travel) => {
    const options = travelModes
      .filter((mode) => mode.mode === travel)
      .map((mode) => ({
        value: mode.type,
        label: mode.type,
      }));
    setTransportationOptions(options);
  };

  const getDistinctModes = (modes) => {
    const modeSet = new Set();
    const distinctModes = [];

    modes.forEach((mode) => {
      if (!modeSet.has(mode.mode)) {
        modeSet.add(mode.mode);
        distinctModes.push(mode);
      }
    });

    return distinctModes;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "Travel") {
      updateTransportationOptions(value);
    }
  };
  console.log("formdata", formData);
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("totalEventDays", totalEventDays);
    console.log("formdata", formData);
    const traveltype = travelModes.find(
      (mode) =>
        mode.mode === formData.Travel && mode.type === formData.Transportation
    );

    console.log("traveltype", traveltype);
    // Define default values
    const defaultAccommodationId = 4; // Set your default accommodation ID here
    const defaultMealPreferenceId = 6; // Set your default meal preference ID here
    const defaultdaysPreferenceId = 1;

    const transformedData = {
      eventId: eventDetails.eventId,
      travelModeId: traveltype.travelModeId,
      location: {
        locationId: formData.LocationID ? formData.LocationID : 0,
        location: formData.locationAddress,
      },
      accommodationId: formData.Accomodation || defaultAccommodationId,
      stayDays: formData.time || defaultdaysPreferenceId,
      mealPreferenceId: formData.Meal || defaultMealPreferenceId,
    };

    try {
      const jsonString = JSON.stringify(transformedData, null, 2);
      console.log(jsonString);
      await postEventConsumptionDetails(transformedData);
      console.log("Data saved successfully");
     setAlertMessage("Data saved successfully");
      setAlertType("success");
      setShowAlert(true);
    } catch (error) {
      console.error("Failed to save data", error);
      setAlertMessage("Error saving data. Please check your inputs and try again.");
      setAlertType("error");
      setShowAlert(true);
    }
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
    if (alertType === 'success') {
      navigate("/events");
    }
  };
  const distinctTravelModes = getDistinctModes(travelModes);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="White_Box">
            <div className="row">
              <div className="col-md-12">
                <EventName />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="MyData-form">
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="row">
                      <div className="col-md-12 Page_Title">
                        <h3>My Information</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                        <label>
                        How will you travel to the Event?
                                    <span className="tooltip-icon">
                                      <i className="fa fa-info-circle" aria-hidden="true" />
                                    <span className="tooltip-text">In case of multiple travel modes, choose the one that covers the maximum distance</span>
                                </span>
                              </label>
                          <select
                            required
                            name="Travel"
                            className="form-control"
                            onChange={handleChange}
                            value={formData.Travel}
                          >
                            <option value=""></option>
                            {distinctTravelModes.map((option, index) => (
                              <option key={index} value={option.mode}>
                                {option.mode}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Type of Transportation</label>
                          <select
                            required
                            name="Transportation"
                            className="form-control"
                            onChange={handleChange}
                            value={formData.Transportation}
                          >
                            <option value=""></option>
                            {TransportOptions.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Starting Location of your journey</label>
                          <input
                            required
                            className="form-control"
                            id="location"
                            ref={locationRef}
                            placeholder="Enter location"
                            value={formData.locationAddress}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                locationAddress: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>How long will you attend the event? </label>
                          <select
                            required
                            name="time"
                            className="form-control"
                            value={formData.time}
                            onChange={handleChange}
                          >
                            {[...Array(totalEventDays)].map((option, index) => (
                              <option
                                key={index}
                                value={index + 1}
                                selected={formData.Time === index + 1}
                              >
                                {index + 1} Days
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Which type of hotel you will stay in?</label>
                          <select
                            required
                            name="Accomodation"
                            className="form-control"
                            value={formData.Accomodation}
                            onChange={handleChange}
                          >
                            <option value="4">NA</option>
                            <option value="1">3 Star</option>
                            <option value="2">4 Star</option>
                            <option value="3">5 Star</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Meal Preference</label>
                          <select
                            name="Meal"
                            className="form-control"
                            value={formData.Meal}
                            onChange={handleChange}
                          >
                            {eventDetails.mealType.split("|").map((meal) => {
                              switch (meal.trim()) {
                                case "Veg":
                                  return (
                                    <option key="6" value="6">
                                      Veg
                                    </option>
                                  );
                                case "NonVeg":
                                  return (
                                    <option key="7" value="7">
                                      Non-Veg
                                    </option>
                                  );
                                case "Vegan":
                                  return (
                                    <option key="8" value="8">
                                      Vegan
                                    </option>
                                  );
                                default:
                                  return null;
                              }
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-right">
                        <button type="submit" className="btn save-button">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAlert && (
          <CustomAlert
            message={alertMessage}
            type={alertType}
            onClose={handleCloseAlert}
          />
        )}
    </div>
  );
};

export default MyData;
