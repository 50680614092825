import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import EcoIndex from './EcoIndex';
import EventDetailsSidebar from "./eventDetailsSidebar";
import html2pdf from 'html2pdf.js';
import { useEvent } from "./EventDetailsContext";
import "./Report.css";
import EventName from "./EventName";
import PreviewReport from './previewReport';
import {
  getEmissionPreEventActivity,
  getEmissionDuringEventActivity,
  getEmissionPostEventActivity,
  getDashboardData,
  isAttendeeDetailsInitiated,
  GetEnergyConsumptionList
} from './apiService';
import homePic from './assets/home-pic.jpg';
import sproutImg from './assets/sprout.png';
import coolLogo from './assets/cool-logo.jpg';
import image1 from './assets/img-02.jpg';
import image2 from './assets/img-02..jpg'
import DoughnutChart from './DoughnutChart';
import DoughnutChart2 from './DoughnutChart2';
import DoughnutChart3 from "./DoughnutChart3";
import TravelModeDoughnutChart from './AttendeeTravelChart';
import MealConsumptionDoughnutChart from './attendeeMealChart';

const Report = () => {

  const location = useLocation();
  const { eventDetails, setEventDetails } = useEvent();
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const reportRef = useRef(null);
  const [preEventData, setPreEventData] = useState([]);
  const [duringEventData, setDuringEventData] = useState([]);
  const [postEventData, setPostEventData] = useState([]);
  const [totalEstimatedCarbonEmission, setTotalEstimatedCarbonEmission] = useState(null);
  const [attendeeDetails, setAttendeeDetails] = useState([]);
  const [energyConsumptionData, setEnergyConsumptionData] = useState([]);
  const [wasteGeneration, setWasteGeneration] = useState('0.00');
  const [detailedData, setDetailedData] = useState([]);
  const reportRef1 = useRef();
  const reportRef2 = useRef();
  const reportRef3 = useRef();
  const reportRef4 = useRef();
  const reportRef5 = useRef();
  const reportRef6 = useRef();
  const reportRef7 = useRef();
  const reportRef8 = useRef();
  const reportRef9 = useRef();
  const reportRef10 = useRef();
  const reportRef11 = useRef();
  const reportRef12 = useRef();
  const reportRef13 = useRef();
  const reportRef14 = useRef();
  const reportRef15 = useRef();


  useEffect(() => {
    if (location.state?.event) {
      setEventDetails(location.state.event);
      console.log("eventDetails: ",eventDetails);
    }
  }, [location.state, setEventDetails, eventDetails.eventId]);

  const handleDownloadPDF = () => {
 
    const container = document.createElement('div');
    [reportRef1, reportRef2, reportRef3, reportRef4, reportRef5,reportRef6, reportRef7, reportRef8, reportRef9, reportRef10, reportRef11, reportRef12, reportRef13, reportRef14, reportRef15].forEach(ref => {
      if (ref.current) {
        container.appendChild(ref.current.cloneNode(true)); 
        container.appendChild(document.createElement('div')).style.pageBreakAfter = 'always'; 
      }
    });

    const opt = {
      margin: 0.5,
      filename: 'report.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }, 
    };

    html2pdf().set(opt).from(container).save();
  };


  useEffect(() => {
    const fetchEmissionData = async () => {
      try {
        // Fetch pre-event emission data
        const preEventResponse = await getEmissionPreEventActivity(eventDetails.eventId);
        setPreEventData(preEventResponse.mainData);
  
        // Fetch during-event emission data
        const duringEventResponse = await getEmissionDuringEventActivity(eventDetails.eventId);
       // Flatten the detailedData to extract subcategories
      const flattenedData = duringEventResponse.detailedData.flatMap(item => 
        item.data.map(subItem => ({
          emissionType: item.emissionType,
          emissionSubcategory: subItem.emissionSubcategory,
          value: subItem.value
        }))
      );

      setDuringEventData(duringEventResponse.mainData);
      setDetailedData(flattenedData); 
        
        // Fetch post-event emission data
        const postEventResponse = await getEmissionPostEventActivity(eventDetails.eventId);
        setPostEventData(postEventResponse.mainData);
        // Check if the response contains relevant data
      if (postEventResponse && postEventResponse.mainData) {
        // Calculate the total emission by summing up the 'value' fields of all items
        const totalWasteEmission = postEventResponse.mainData.reduce((acc, item) => {
          // Ensure the value is a valid number, otherwise use 0
          const value = parseFloat(item.value) || 0;
          return acc + value;
        }, 0);

        // Store the total emission
        setWasteGeneration(totalWasteEmission.toFixed(2));
      }
        // Fetch dashboard data for total emission
        const dashboardResponse = await getDashboardData(eventDetails.eventId);
        setTotalEstimatedCarbonEmission(dashboardResponse.totalEstimatedCarbonEmission);
  
        // Fetch attendee details
        const attendeeResponse = await isAttendeeDetailsInitiated(eventDetails.eventId);
        console.log("Full Attendee Response:", attendeeResponse);
  
        if (attendeeResponse && attendeeResponse.attendee) {
          setAttendeeDetails(attendeeResponse.attendee); // Access the 'attendee' property
          console.log("Attendee Details:", attendeeResponse.attendee);
        } else {
          console.log("No Attendee Details found.");
        }
      // Fetch energy consumption data
      const energyConsumptionResponse = await GetEnergyConsumptionList(eventDetails.eventId);
      setEnergyConsumptionData(energyConsumptionResponse); // Store data in state
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    };
  
    fetchEmissionData();
  }, [eventDetails.eventId]);

  const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image3, setImage3] = useState(null);
    const [image4, setImage4] = useState(null);
    const [image5, setImage5] = useState(null);
    const [image6, setImage6] = useState(null);
    const [image7, setImage7] = useState(null);


    const handleImageUpload1 = (event) => {
      setImage1(URL.createObjectURL(event.target.files[0]));
  };
  const handleImageUpload2 = (event) => {
      setImage2(URL.createObjectURL(event.target.files[0]));
  };
  const handleImageUpload3 = (event) => {
      setImage3(URL.createObjectURL(event.target.files[0]));
  };
  const handleImageUpload4 = (event) => {
      setImage4(URL.createObjectURL(event.target.files[0]));
  };
  const handleImageUpload5 = (event) => {
      setImage5(URL.createObjectURL(event.target.files[0]));
  };
  const handleImageUpload6 = (event) => {
      setImage6(URL.createObjectURL(event.target.files[0]));
  };
  const handleImageUpload7 = (event) => {
    setImage7(URL.createObjectURL(event.target.files[0]));
};

  const filteredData = preEventData.filter(item => 
    item.emissionType === "Invitation Emails" || item.emissionType === "Printed Invitations" || item.emissionType === "Meeting"
  );
  // Extract the quantity for "Fuel Consumption (Diesel)"
  const fuelConsumption = energyConsumptionData.find(item => item.energyName === 'Fuel Consumption (Diesel)');
  const fuelConsumptionQuantity = fuelConsumption ? fuelConsumption.quantity.toFixed(2) : '0.00';

  const electricityConsumption = energyConsumptionData.find(item => item.energyName === 'Electricity Consumption');
  const electricityConsumptionQuantity = electricityConsumption ? electricityConsumption.quantity.toFixed(2) : '0.00';
 // Extract values from duringEventData
 const snacksDrinks = duringEventData.find(item => item.emissionType === 'Meals');
 const snacksDrinksQuantity = snacksDrinks ? snacksDrinks.cumulativevalue.toFixed(2) : '0.00';

 const materialConsumption = duringEventData.find(item => item.emissionType === 'Material Consumption');
 const materialConsumptionQuantity = materialConsumption ? materialConsumption.cumulativevalue.toFixed(2) : '0.00';
  

 const getEmissionValue = (subcategory) => {
  console.log("Available Subcategories:", detailedData.map(item => item.emissionSubcategory)); // Log to check
  const dataItem = detailedData.find(item => item.emissionSubcategory === subcategory);
  return dataItem ? dataItem.value.toFixed(2) : '0.00';
};
const emissionsData = {
  scope1: {
    invitationEmails: filteredData.find(item => item.emissionType === "Invitation Emails")?.cumulativevalue.toFixed(2) || '0.00',
    printedInvitations: filteredData.find(item => item.emissionType === "Printed Invitations")?.cumulativevalue.toFixed(2) || '0.00',
    fuelConsumption: fuelConsumptionQuantity,
  },
  scope2: {
    electricityConsumption: electricityConsumptionQuantity,
  },
  scope3: {
    snacksDrinks: snacksDrinksQuantity,
    materialConsumption: materialConsumptionQuantity,
    attendeeTravel: getEmissionValue("Attendee travel"),
    attendeeAccommodation: getEmissionValue("Attendee Accommodation"),
    attendeeMeals: getEmissionValue("Attendee Meal"),
    wasteGeneration: wasteGeneration,
    preEventMeetings: filteredData.find(item => item.emissionType === "Meeting")?.cumulativevalue.toFixed(2) || '0.00',
  },
  totalEmission: totalEstimatedCarbonEmission ? totalEstimatedCarbonEmission.toFixed(2) : 'Loading...',
};

console.log("pre event data : ",preEventData);
  return (
    <div className="report-container">
      <EcoIndex />
      <div className="main-content">
        <EventDetailsSidebar eventDetails={eventDetails} />
        <div className="container-fluid" >
      <div className="row">
         <div className="col-md-12">
             <div className="White_Box">
              <div className="row">
                <div className="col-md-12">
                <EventName />
                </div>
              </div>
              <div className="row">
              <div className="col-md-12">
              <div className="report-buttons">
                {/* <button className="btn GreenBtn" onClick={handleGenerateReport}>Generate Report</button> */}
                <PreviewReport eventDetails={eventDetails}
                        sproutImg={sproutImg}
                        coolLogo={coolLogo}
                     
                        preEventData={preEventData}
                        duringEventData={duringEventData}
                        postEventData={postEventData}
                        totalEstimatedCarbonEmission={totalEstimatedCarbonEmission} 
                        attendeeTravelConsumptions={attendeeDetails.attendeeTravelConsumptions}
                        attendeeMealConsumptions={attendeeDetails.attendeeMealConsumptions}
                        attendeeDetails={attendeeDetails}
                      
                        emissionsData={emissionsData} 
                        image1={image1}
                        image2={image2}
                        image3={image3}
                        image4={image4}
                        image5={image5}
                        image6={image6}
                        image7={image7}
                        />
               {/* <button onClick={handleDownloadPDF}>Download Combined PDF</button> */}
              </div>
            </div>
            </div>
            <div className="row" ref={reportRef1} >
            <div className="col-md-12">
          <div className="left_container" >
            <img src={homePic} className="img-fluid" alt="Carbon Footprint Report"  />
            <div className="left_container_Txt">
              <h1>CARBON FOOTPRINT REPORT</h1>
              <h2>{eventDetails.eventName || "Event Name"}</h2>
              <p>HOST ORGANIZATION: {eventDetails.createdBy || "N/A"}</p>
              <p>EVENT DATE: {eventDetails.startDate} to {eventDetails.endDate}</p>
              <p>EVENT: {eventDetails.eventType || "N/A"}</p>
              <p>LOCATION: {eventDetails.location || "N/A"}</p>
              <p>PARTICIPANT COUNT: {eventDetails.participantCount || "N/A"}</p>
              <p>MEAL TYPE: {eventDetails.mealType || "N/A"}</p>
              <p>WATER PROVISION: {eventDetails.waterProvision || "N/A"}</p>
              <p>ROLE: {eventDetails.myRole || "N/A"}</p>
            </div>
          </div>
          
</div>
</div>     
        
        <div className="row" ref={reportRef2}>
          <div className="col-md-12">
            <div className="PageTitleLogo">
              <div className="pull-left"><img src={sproutImg} width="103" height="50" alt="Sprout" /></div>
              <div className="pull-right"><img src={coolLogo} width="103" height="62" alt="Cool Logo" /></div>
              <div className="clearfix"></div>
            </div>
            <div className="GreenBoxTitle"> PURPOSE OF THE ASSESSMENT </div>
            <div className="mainTxt">
              <p>The purpose of this assessment is to calculate the environmental impact of {eventDetails.eventName || "Event Name"}, {eventDetails.location || "N/A"} held on {eventDetails.startDate} to {eventDetails.endDate} at {eventDetails.location || "N/A"} by evaluating its carbon footprint. Through this process, we aim to promote sustainability by identifying opportunities to reduce greenhouse gas emissions and enhance environmental stewardship within event management practices. </p>
              <p>The purpose of this report is to calculate the carbon footprint and establish a baseline that can serve as a reference for future events to reduce their carbon footprint. </p>
            </div>
            <div className="GreenBoxTitle"> ABOUT THE EVENT </div>
            <div className="mainTxt">
              <p>{eventDetails.eventName || "Event Name"} was designed with sustainability as a core focus, aiming to minimize its environmental impact while maximizing the positive outcomes for attendees. Through careful planning, we incorporated eco-friendly practices at every stage, from transportation and venue selection to catering and waste management. The organizing team was committed to reduce the event's carbon footprint and calculate the total impact of event on environment, and we are proud to present the calculated carbon emissions as part of our effort to promote transparency and continual improvement in sustainability practices.</p>
              
            </div>
          </div>
        </div>
        <div className="row" ref={reportRef3}>
          <div className="col-md-12">
            <div className="PageTitleLogo">
              <div className="pull-left"><img src={sproutImg} width="103" height="50" alt="Sprout" /></div>
              <div className="pull-right"><img src={coolLogo} width="103" height="62" alt="Cool Logo" /></div>
              <div className="clearfix"></div>
            </div>
            <div className="ImgWrp">
              <label htmlFor="imageUpload1" className={`uploadBox ${image1 ? 'hidden' : ''}`}>
                <span>Upload Picture</span>
                <input
                  type="file"
                  id="imageUpload1"
                  className="fileInput"
                  onChange={handleImageUpload1}
                  accept="image/*"
                />
              </label>
              {image1 && <img src={image1} alt="Preview 1" />}
            </div>

            <div className="GreenBoxTitle"> EVENT HIGHLIGHTS</div>
            <div className="GreenBox">
            <ul>
  <li>
    <input 
      type="text" 
      className="inputField" 
      value={`End Date - ${eventDetails.startDate} to ${eventDetails.endDate}`}

      readOnly
    />
  </li>
  <li>
    <input 
      type="text" 
      className="inputField" 
      value={`Total Attendees Participants: ${eventDetails.participantCount}`} 
      readOnly
    />
  </li>
  <li>
    <input 
      type="text" 
      className="inputField" 
      value={`Number of hotel rooms booked: ${eventDetails.hotelRoomsBooked || 'N/A'} Room Booked for Guests`} 
      readOnly
    />
  </li>
  <li>
    <input 
      type="text" 
      className="inputField" 
      value={`Sustainable Strategies Adopted: ${eventDetails.sustainableStrategies || 'Not specified'}`} 
      readOnly
    />
  </li>
  <li>
    <input 
      type="text" 
      className="inputField" 
      placeholder="Cultural & Musical Events" 
    />
  </li>
  <li>
    <input 
      type="text" 
      className="inputField" 
      placeholder="Decarbonization Theme" 
    />
  </li>
  <li>
    <input 
      type="text" 
      className="inputField" 
      placeholder="398 Room Booked for Guests" 
    />
  </li>
</ul>

</div>

          </div>
        </div>
      
        <div className="row" ref={reportRef3}>
          <div className="col-md-12">
            <div className="PageTitleLogo">
              <div className="pull-left"><img src={sproutImg} width="103" height="50" alt="Sprout" /></div>
              <div className="pull-right"><img src={coolLogo} width="103" height="62" alt="Cool Logo" /></div>
              <div className="clearfix"></div>
            </div>
            <div class="GreenBoxTitle"> IMAGES OF EMISSION REDUCTION STRATEGIES</div>
            <div class="mainTxt">
  <div class="row" ref={reportRef}>
    <div class="col-md-6">
      <div class="ImgWrp"> 
      
      <label htmlFor="imageUpload2"  className={`uploadBox ${image2 ? 'hidden' : ''}`}>
                <span>Upload Picture</span>
                <input
                    type="file"
                    id="imageUpload2"
                    className="fileInput"
                    onChange={handleImageUpload2}
                    accept="image/*"
                />
            </label>
            {image2 && <img src={image2} alt="Preview 2" />}
        <div class="textWrp">
          Climate Appropriate Clothing
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="ImgWrp"> 
      <label htmlFor="imageUpload3"  className={`uploadBox ${image3 ? 'hidden' : ''}`}>
                <span>Upload Picture</span>
                <input
                    type="file"
                    id="imageUpload3"
                    className="fileInput"
                    onChange={handleImageUpload3}
                    accept="image/*"
                />
            </label>
            {image3 && <img src={image3} alt="Preview 3" />}
        <div class="textWrp">
          Digital Registration
        </div>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="col-md-6">
      <div class="ImgWrp"> 
      <label htmlFor="imageUpload4"  className={`uploadBox ${image4 ? 'hidden' : ''}`}>
                <span>Upload Picture</span>
                <input
                    type="file"
                    id="imageUpload4"
                    className="fileInput"
                    onChange={handleImageUpload4}
                    accept="image/*"
                />
            </label>
            {image4 && <img src={image4} alt="Preview 4" />}
        <div class="textWrp">
          Glass Bottles
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="ImgWrp"> 
      <label htmlFor="imageUpload5"  className={`uploadBox ${image5 ? 'hidden' : ''}`}>
                <span>Upload Picture</span>
                <input
                    type="file"
                    id="imageUpload5"
                    className="fileInput"
                    onChange={handleImageUpload5}
                    accept="image/*"
                />
            </label>
            {image5 && <img src={image5} alt="Preview 5" />}
        <div class="textWrp">
          Local Food
        </div>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="col-md-6">
      <div class="ImgWrp"> 
      <label htmlFor="imageUpload6"  className={`uploadBox ${image6 ? 'hidden' : ''}`}>
                <span>Upload Picture</span>
                <input
                    type="file"
                    id="imageUpload6"
                    className="fileInput"
                    onChange={handleImageUpload6}
                    accept="image/*"
                />
            </label>
            {image6 && <img src={image6} alt="Preview 6" />}
        <div class="textWrp">
          Recyclable Fabric Banners
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="ImgWrp"> 
      <label htmlFor="imageUpload7"  className={`uploadBox ${image7 ? 'hidden' : ''}`}>
                <span>Upload Picture</span>
                <input
                    type="file"
                    id="imageUpload7"
                    className="fileInput"
                    onChange={handleImageUpload7}
                    accept="image/*"
                />
            </label>
            {image7 && <img src={image7} alt="Preview 7" />}
        <div class="textWrp">
          Shuttle for Pick-up & Drop
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>

          </div>
        </div>


  <div class="row" ref={reportRef4}>
    <div class="col-md-12">
      <div class="PageTitleLogo">
        <div class="pull-left"><img src={sproutImg} width="103" height="50" alt="Sprout" /></div>
        <div class="pull-right"><img src={coolLogo} width="103" height="50" alt="Sprout" /></div>
        <div class="clearfix"></div>
      </div>
      <div class="GreenBoxTitle"> METHODOLOGY </div>
      <div class="mainTxt">
        <p>Calculating  the carbon footprint of an event involves assessing all activities related to  the event that contribute to greenhouse gas emissions. This includes direct  emissions from energy consumption at the venue, indirect emissions from  attendee and staff  travel,  accommodation, and waste management, as well as emissions from the production  and disposal of materials used. Data on energy usage, transportation distances,  waste generated, and other relevant factors are collected and multiplied by  appropriate emission factors to convert them into carbon dioxide equivalents  (CO2e). The total emissions from all sources are then aggregated to determine  the event's overall carbon footprint. This information can be used to identify  the largest sources of emissions, report on the environmental impact, and  explore options for offsetting or reducing the event's carbon footprint.</p>
        <p>For  better understanding all activities has been divided in the Pre-Event, During  Event and Post Event section so that monitoring of data and calculation became  easy. This methodology enhanced the boundary of event as it includes emissions  associated with pre-event planning also. </p>
      </div>
      <div class="GreenBoxTitle"> CARBON EMISSION HEADS </div>
      <div class="row">
		 <div class="col-md-4">
			 <div class="PreEvent">
				 <div class="title">Pre Event</div>
			 <div>
			   <ul>
			     <li>
			       Marketing & Invitation Mails		         </li>
			     <li>Printed Invitation		         </li>
			     <li>Printed Marketing Materials </li>
		       </ul>
			 </div>
			 </div>
		  
	    </div> 
		  
		  <div class="col-md-4">
			  <div class="DuringEvent">
				  <div class="title">During Event</div>
			 <div>
			   <ul>
			     <li>Attendee Travel, Accommodation &amp; Meals</li>
			     <li>Energy Consumption</li>
			     <li>Snacks &amp; Drinks</li>
			     <li>Material Consumption</li>
		       </ul>
			 </div>
			  </div>
		  
	    </div> 
		  
		  <div class="col-md-4">
			  <div class="PostEvent">
				  <div class="title">Post Event</div>
			 <div>
			   <ul>
			     <li>Quantity of Waste Generated</li>
		       </ul>
			 </div>
			  </div>
		  
	    </div> 
		  
        </div>
    </div>
  </div>
  <div class="row" ref={reportRef5}>
    <div class="col-md-12">
      <div class="PageTitleLogo">
        <div class="pull-left"><img src={sproutImg} width="103" height="50" alt="Sprout" /></div>
        <div class="pull-right"><img src={coolLogo} width="103" height="50" alt="Sprout" /></div>
        <div class="clearfix"></div>
      </div>
      <div class="mainTxt">
      <table className="table table-bordered">
      <thead>
        <tr>
          <th valign="top" bgcolor="#007272"><strong>Activities</strong></th>
          <th valign="top" bgcolor="#007272"><strong>Emissions (kgCO2e)</strong></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan="2" valign="top"><strong>Pre Event</strong></td>
        </tr>
        {preEventData.map((item, index) => (
          <tr key={index}>
            <td valign="top">{item.emissionType}</td>
            <td valign="top">{item.cumulativevalue.toFixed(2)}</td>
          </tr>
        ))}

        <tr>
          <td colSpan="2" align="center" valign="top" bgcolor="#B9CDE5"><strong>During Event</strong></td>
        </tr>
        {duringEventData.map((item, index) => (
          <tr key={index}>
            <td valign="top">{item.emissionType}</td>
            <td valign="top">{item.cumulativevalue.toFixed(2)}</td>
          </tr>
        ))}

        <tr>
          <td colSpan="2" align="center" valign="top" bgcolor="#B9CDE5"><strong>Post Event</strong></td>
        </tr>
        {postEventData.map((item, index) => (
          <tr key={index}>
            <td valign="top">{item.emissionType}</td>
            <td valign="top">{item.cumulativevalue.toFixed(2)}</td>
          </tr>
        ))}

<tr>
          <td rowspan="2" align="left" valign="baseline" bgcolor="#DDD9C3"><strong>Total Emission from CoOL Conclave</strong></td>
          <td valign="top" bgcolor="#DDD9C3"><strong>{totalEstimatedCarbonEmission ? totalEstimatedCarbonEmission.toFixed(2) : 'Loading...'}</strong></td>
        </tr>
        <tr>
          <td valign="top" bgcolor="#DDD9C3"> <strong>{totalEstimatedCarbonEmission ? (totalEstimatedCarbonEmission / 1000).toFixed(2) + ' tCO2e' : 'Loading...'}</strong></td>
        </tr>
      </tbody>
    </table>

      </div>
      <div class="mainTxt">
        <p><strong>BOUNDARIES:</strong></p>
        <p><span class="blueClr">event</span> duration  (days),  attendees( number )</p>
        <p><span class="blueClr">travel: </span> guest, participants and organizers travel by mode (air, private vehicle, public transport) and distance.</p>
        <p><span class="blueClr">accommodation: </span>accommodation:  hotel nights for guests, build crew or stand staffing.</p>
        <p><span class="blueClr">catering: </span> includes number of meals ( non-vegetarian, vegetarian, vegan ) consumed by guests, crew, build staff for duration of event.</p>
        <p><span class="blueClr">energy:</span>  actual consumption as estimated or measured by venue (kWh)</p>
        {eventDetails.boundary === "Material Consumption" && (
            <p><span className="blueClr">materials: </span> printed matter, plastics, recyclable materials and other materials used in stand build &amp; deliver</p>
          )}
        <p><span class="blueClr">transportation:</span>  transported weight of infrastructure, AV, materials, furniture and other  stand-based items, distance and mode of transportation.</p>
        <p><span class="blueClr">waste:</span>  recyclable and residual waste</p>
      </div>
    </div>
  </div>
  <div class="row" ref={reportRef6}>
    <div class="col-md-12">
      <div class="PageTitleLogo">
        <div class="pull-left"><img src={sproutImg} width="103" height="50" alt="Sprout" /></div>
        <div class="pull-right"><img src={coolLogo} width="103" height="50" alt="Sprout" /></div>
        <div class="clearfix"></div>
      </div>
      <div class="mainTxt">
      <div className="chart-table-container">
      <div
          className="chart-section"
          style={{ border: 'none !important', boxShadow: 'none !important' }}
        >
        <DoughnutChart eventId={eventDetails.eventId} />
      </div>

      <div className="table-section">
        <table>
          <thead>
            <tr>
           
              <th>Emission Type</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(preEventData).map((key, index) => (
              <tr key={index}>
             
                <td>{preEventData[key].emissionType}</td> 
                <td>{preEventData[key].cumulativevalue}</td> 
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
      </div>
      
    </div>
  </div>
	
	<div class="row" ref={reportRef7}>
    <div class="col-md-12">
      <div class="PageTitleLogo">
        <div class="pull-left"><img src={sproutImg} width="103" height="50" alt="Sprout" /></div>
        <div class="pull-right"><img src={coolLogo} width="103" height="50" alt="Sprout" /></div>
        <div class="clearfix"></div>
      </div>
      <div class="mainTxt">
        
      <div className="chart-table-container">
      <div
        className="chart-section"
        style={{ border: 'none', boxShadow: 'none' }}
      >
        <DoughnutChart2 eventId={eventDetails.eventId} />
      </div>
      <div className="table-section">
        <table>
          <thead>
            <tr>
        
              <th>Emission Type</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(duringEventData).map((key, index) => (
              <tr key={index}>
          
                <td>{duringEventData[key].emissionType}</td> 
                <td>{duringEventData[key].cumulativevalue}</td> 
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
      </div>
      
    </div>
  </div>
	
	
	<div class="row" ref={reportRef8}>
    <div class="col-md-12">
      <div class="PageTitleLogo">
        <div class="pull-left"><img src={sproutImg} width="103" height="50" alt="Sprout" /></div>
        <div class="pull-right"><img src={coolLogo} width="103" height="50" alt="Sprout" /></div>
        <div class="clearfix"></div>
      </div>
      <div className="chart-table-container">
      <div
        className="chart-section"
        style={{ border: 'none', boxShadow: 'none' }}
      >
        <DoughnutChart3 eventId={eventDetails.eventId} />
      </div>
      <div className="table-section">
        <table>
          <thead>
            <tr>
              
              <th>Emission Type</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(postEventData).map((key, index) => (
              <tr key={index}>
              
                <td>{postEventData[key].emissionType}</td> 
                <td>{postEventData[key].cumulativevalue}</td> 
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  
    </div>
  </div>
	
	<div class="row" ref={reportRef9}>
    <div class="col-md-12">
      <div class="PageTitleLogo">
        <div class="pull-left"><img src={sproutImg} width="103" height="50" alt="Sprout" /></div>
        <div class="pull-right"><img src={coolLogo} width="103" height="50" alt="Sprout" /></div>
        <div class="clearfix"></div>  
      </div>
      <div class="mainTxt">
      <TravelModeDoughnutChart attendeeTravelConsumptions={attendeeDetails.attendeeTravelConsumptions} />
      </div>
      
    </div>
  </div>
  <div class="row" ref={reportRef10}>
    <div class="col-md-12">
      <div class="PageTitleLogo">
        <div class="pull-left"><img src={sproutImg} width="103" height="50" alt="Sprout" /></div>
        <div class="pull-right"><img src={coolLogo} width="103" height="50" alt="Sprout" /></div>
        <div class="clearfix"></div>
      </div>
      <div class="mainTxt">
        
      <MealConsumptionDoughnutChart attendeeMealConsumptions={attendeeDetails.attendeeMealConsumptions} />
      </div>
      
    </div>
  </div>
  <div className="GreenBoxTitle" ref={reportRef11}>Emissions Breakdown by Scope and Activity</div>
<div className="mainTxt" ref={reportRef12}>
<table className="table table-bordered">
      <thead>
        <tr>
          <th>Scope</th>
          <th>Activities</th>
          <th>Emissions (kgCO2e)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td rowSpan="2" valign="top">Scope 1 (Direct Emissions)</td>
          <td valign="top">Invitation Emails</td>
          <td valign="top">
            {filteredData.find(item => item.emissionType === "Invitation Emails")?.cumulativevalue.toFixed(2) || '0.00'}
          </td>
        </tr>
        <tr>
          <td valign="top">Printed Invitations</td>
          <td valign="top">
            {filteredData.find(item => item.emissionType === "Printed Invitations")?.cumulativevalue.toFixed(2) || '0.00'}
          </td>
        </tr>
        <tr>
        <td></td>
          <td valign="top">Fuel Consumption in DG Sets and Transportation</td>
          <td valign="top">{fuelConsumptionQuantity}</td>
        </tr>
        <tr>
          <td valign="top">Scope 2 (Indirect Emissions)</td>
          <td valign="top">Electricity Consumption</td>
          <td valign="top">{electricityConsumptionQuantity}</td>
        </tr>
        <tr>
          <td rowSpan="8" valign="top">Scope 3 (Indirect Emissions)</td>
          <td valign="top">Snacks/Drinks</td>
          <td valign="top">{snacksDrinksQuantity}</td>
        </tr>
        <tr>
          <td valign="top">Material Consumption</td>
          <td valign="top">{materialConsumptionQuantity}</td>
        </tr>
        <tr>
          <td valign="top">Attendee Travel</td>
          <td valign="top">{getEmissionValue("Attendee travel")}</td>
        </tr>
        <tr>
          <td valign="top">Attendee Accommodation</td>
          <td valign="top">{getEmissionValue("Attendee Accommodation")}</td>
        </tr>
        <tr>
          <td valign="top">Attendee Meals</td>
          <td valign="top">{getEmissionValue("Attendee Meal")}</td>
        </tr>
        <tr>
          <td valign="top">Waste Generation</td>
          <td valign="top"> {wasteGeneration }</td>
        </tr>
        <tr>
          <td valign="top">Pre Event Meetings</td>
          {filteredData.find(item => item.emissionType === "Meeting")?.cumulativevalue.toFixed(2) || '0.00'}
        </tr>
        <tr>
          <td valign="top" bgcolor="#DDD9C3"><strong>Total Emission from CoOL Conclave</strong></td>
          <td valign="top" bgcolor="#DDD9C3"><strong>{totalEstimatedCarbonEmission ? totalEstimatedCarbonEmission.toFixed(2) : 'Loading...'}</strong></td>
        </tr>
      </tbody>
    </table>
</div>  


  
	<div class="row" ref={reportRef13}>
    <div class="col-md-12">
      <div class="PageTitleLogo">
        <div class="pull-left"><img src={sproutImg} width="103" height="50" alt="Sprout" /></div>
        <div class="pull-right"><img src={coolLogo} width="103" height="50" alt="Sprout" /></div>
        <div class="clearfix"></div>
      </div>
      <div class="GreenBoxTitle"> CONCLUSION </div>
      <div class="mainTxt">
        <p>{eventDetails.eventName || "Event Name"} team has taken significant steps to minimize the event's carbon  footprint, implementing various strategies such as energy-efficient venue  selection, promoting sustainable transportation options, and reducing waste.  Despite these efforts, a total of 153 carbon credits are required to fully  offset the residual emissions from the event. By purchasing these carbon  credits, the team aims to neutralize their environmental impact, supporting  projects that reduce or remove an equivalent amount of greenhouse gases from  the atmosphere. This commitment underscores the event's dedication to  sustainability and decarbonization.</p>
</div>
      
      
    </div>
  </div>

</div>
</div>
</div>
</div>

        
        
        
      </div>
    </div>
  );
};

export default Report;
