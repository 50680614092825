import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import "./DoughnutChart.css";
import { getOrganizerEmission } from "./apiService";

const OrganizerPieChart = ({ eventId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getOrganizerEmission(eventId);
        console.log("OrganizerPieChart Data:", response);
        const mainData = response.mainData;
        const values = mainData.map((item) => item.value);
        setData(values);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err);
        setLoading(false);
      }
    };

    if (eventId) {
      fetchData();
    }
  }, [eventId]);

  const chartData = {
    labels: ["Travel", "Accommodation"],
    datasets: [
      {
        data: data,
        backgroundColor: ["#FF6384", "#FFCE56"],
      },
    ],
  };
  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || "";
            const value = context.raw.toFixed(2);
            return `${label}: ${value} KgCO₂`;
          },
        },
      },
    },
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data</p>;

  return (
    <div className="row justify-content-center">
      <div className="col-md-4 PaiChartWrp  ">
        <div className="card">
          <div className="chart-container">
            <p>My Contribution in Total Carbon Emission</p>
            <Pie data={chartData} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizerPieChart;
